<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>岗位管理</el-breadcrumb-item>
        <el-breadcrumb-item>岗位课程</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container yt-container-flex" :class="{ 'is-pack-up': isPackUp }" style="height: 100%">
      <div class="yt-content-tree">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <YTIcon style="margin: 0 5px 0 19px; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>岗位选择</p>
          </div>
          <div class="tree">
            <el-tree :data="treeData" :props="defaultProps" node-key="id" :highlight-current="true" @node-click="choosePost">
              <div
                class="custom-tree-node tree-node"
                style="width: 100%;display: flex;justify-content: space-between;height: 100%;line-height: 30px"
                slot-scope="{ node, data }"
                :style="showBackground === true && data.id === dataId ? 'background-color: #e1e8f2' : ''"
              >
                <el-tooltip effect="dark" :content="node.label" :disabled="node.label.length < 10" placement="top-start">
                  <div class="el-tree-node__label" @click="choosePost(data)">
                    {{ node.label }}
                  </div>
                </el-tooltip>
              </div>
            </el-tree>
          </div>
        </template>
      </div>
      <div class="yt-resize">
        ⋮
      </div>
      <div class="yt-content">
        <div style="display: flex">
          <div style="width: 100%">
            <div class="bg-shadow func-bar">
              <div class="button-container" style="white-space: nowrap">
                <el-button type="primary" size="small" @click="toAddCourse">
                  <div>
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-xinzeng" />
                    </svg>
                    <p>添加课程</p>
                  </div>
                </el-button>
                <el-button v-if="isShowCard" size="small" @click="handleDelCourses">
                  <div>
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-shanchu" />
                    </svg>
                    <p>删除</p>
                  </div>
                </el-button>
                <el-button v-if="isShowCard" size="small" :disabled="itemData.length === 0" @click="moveVisible = true">
                  <div>
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-yidong" />
                    </svg>
                    <p>移动到岗位</p>
                  </div>
                </el-button>
              </div>
              <div class="search-container">
                <Input
                  v-model="searchForm.name"
                  search
                  placeholder="课程名称模糊搜索"
                  style="margin-left: auto;width: 250px;margin-right: 20px;"
                  @on-search="searchByKeyword"
                  @on-focus="
                    searchForm = {
                      name: searchForm.name,
                      beginTime: '',
                      endTime: '',
                      status: ''
                    }
                  "
                />
                <Icon type="ios-apps-outline" size="32" style="margin-right: 20px;cursor: pointer" @click="showCard" v-if="false" />
                <div class="showTable" @click="isShowCard = !isShowCard" v-if="!isShowCard" title="列表">
                  <img src="@/assets/group884.png" height="16" width="16" />
                </div>
                <div class="showTable" @click="isShowCard = !isShowCard" v-if="isShowCard" title="视图">
                  <img src="@/assets/group858.png" height="16" width="16" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="isShowCard">
          <el-table
            class="yt-table"
            height="calc(100% - 110px)"
            :data="courseCard.data"
            v-loading="loading"
            element-loading-text="加载中"
            @row-click="gotoCourseDetail"
            @selection-change="selectCourses"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="name" label="课程名称" show-overflow-tooltip />
            <el-table-column prop="description" label="描述" show-overflow-tooltip />
            <el-table-column prop="creatorName" label="创建者" />
            <el-table-column label="创建日期" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ $formatTime(scope.row.createTime, 'yyyy-MM-dd hh:mm') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <Tooltip content="编辑" placement="bottom">
                  <JYIcon href="#icon-a-bianji" class="buttom" @click="handleEdit(scope.row)" />
                </Tooltip>
                <Tooltip content="删除" placement="bottom" style="margin-left: 10px">
                  <svg class="icon buttom" aria-hidden="true" v-on:click.stop="handleDelCourse(scope.row.courseId)" style="font-size: 14px">
                    <use xlink:href="#icon-shanchu" />
                  </svg>
                </Tooltip>
                <Tooltip content="移动到分类" :transfer="true" placement="bottom" style="margin-left: 10px">
                  <svg class="icon buttom" aria-hidden="true" @click.stop="classMoveItem(scope.row)">
                    <use xlink:href="#icon-yidong" />
                  </svg>
                </Tooltip>
              </template>
            </el-table-column>
          </el-table>
          <Page
            class="yt-page"
            :class="'yt-page-' + (courseCard.total.toString().length > 1 ? courseCard.total.toString().length : 2)"
            show-total
            :page-size-opts="[10, 20, 40, 100]"
            show-sizer
            show-elevator
            :total="courseCard.total"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
            :current="pageNumber + 1"
          />
        </template>
        <template v-else>
          <div class="scroll-container" v-if="!isShowCard" :style="{ height: cardH + 'px' }">
            <Scroll :on-reach-bottom="handleReachBottom" :distance-to-edge="10" :loading-text="loadingText" ref="scroll">
              <div class="course-list">
                <CourseCard
                  :course="course"
                  v-for="(course, index) in courseCard.data"
                  :key="index"
                  @on-edit="handleEdit(course)"
                  @on-del="handleDelCourse(course.courseId)"
                />
                <div class="top" v-if="backTop" @click="back">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-bottom" />
                  </svg>
                </div>
              </div>
            </Scroll>
          </div>
        </template>
      </div>
    </div>
    <!--   -->
    <!--   -->
    <!--    </div>-->
    <ConfirmDialog ref="deleteCourseDialog" :title="'删除课程'" @on-ok="delCourse">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        {{ deleteTitle }}
      </div>
    </ConfirmDialog>
    <!--    <DeleteModal v-model="deleteVisible" :title="`${deleteTitle}`" :loading="deleteLoading" @on-ok="delCourse">-->
    <!--      <div style="height: 1px"></div>-->
    <!--    </DeleteModal>-->
    <EditModal v-model="edit" :form="editForm" @on-reflash-list="searchByKeyword" />
    <el-dialog class="yt-dialog yt-dialog-large" :visible="addCourse" title="添加课程" top="20px" @close="addCourse = false">
      <NewPostCourses ref="newCourse" @on-cancle="addCancel" :addCourse="addCourse" :itemPostId="postId" @on-ok="addFinish" />
    </el-dialog>
    <el-dialog
      class="yt-dialog yt-dialog-default"
      :visible.sync="moveVisible"
      title="移动到岗位"
      :close-on-click-modal="false"
      :before-close="moveClose"
      @closed="moveClosed"
    >
      <div class="moveClass" style="max-height: 500px; overflow-y: auto">
        <el-tree @node-click="lessonMove" :data="treeData" ref="tree" :props="defaultProps"> </el-tree>
      </div>
      <div class="footer" slot="footer">
        <el-button @click="moveVisible = false" class="button">取消</el-button>
        <el-button type="primary" @click="moveSubmit" :loading="moveLoading" class="button">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import courseApi from '@api/course'
import DeleteModal from '@components/common/DeleteModal'
import CourseCard from './CourseCard'
import EditModal from './EditModal'
import userApi from '@api/user'
import JYIcon from '../../common/JYIcon'
import functionApi from '@api/functionApi'
import NewCourse from '@components/manage/course/NewCourse'
import NewPostCourses from '@components/manage/course/NewPostCourses'
import ConfirmDialog from '../../common/dialog/ConfirmDialog'
import YTIcon from '../../common/YTIcon'

export default {
  components: {
    NewCourse,
    EditModal,
    CourseCard,
    DeleteModal,
    JYIcon,
    NewPostCourses,
    ConfirmDialog,
    YTIcon
  },
  created() {
    this.showBackground = true
    if (this.$route.query.addCourse !== undefined) {
      this.addCourse = this.$route.query.addCourse
    }
    this.getAllPostById()
    this.getRooter()
  },
  data() {
    return {
      dataId: 0,
      moveVisible: false, //移动弹窗开关
      moveLoading: false, //移动弹窗防抖
      postId2: null, //移动树岗位id
      itemData: '',
      showBackground: false,
      additem: '',
      isShowOut: false,
      isShow: false,
      isPackUp: false,
      addCourse: false,
      cardH: document.body.clientHeight - 204,
      treeData: [],
      defaultProps: {
        children: 'posts',
        label: 'title'
      },
      searchType: '',
      isShowCard: true,
      showManager: false,
      edit: false,
      deleteVisible: false,
      deleteTitle: '',
      deleteLoading: false,
      addModal: false,
      loading: true,
      loadingText: '加载中',
      backTop: false,
      courses: {
        data: [],
        total: 0
      },
      editForm: {},
      pageNumber: 0,
      pageSize: 10,
      searchForm: {
        name: '',
        beginTime: '',
        endTime: '',
        status: ''
      },
      searchVisible: false,
      delCourseIds: [],
      selectIds: [],
      searchKeyword: '',
      delStatus: '',
      courseCard: {
        total: 0,
        page: 0,
        data: []
      },
      managerListHeight: window.innerHeight,
      courseId: [],
      managerId: '',
      courseManagerStatus: '',
      managerList: [],
      postId: 0,
      itemPostId: 0
    }
  },
  watch: {
    searchKeyword() {
      this.pageNumber = 0
      this.pageSize = 10
      this.courseCard.page = 0
      this.searchType = ''
    }
  },
  mounted() {
    this.$dragDiv()
  },
  methods: {
    deleteClose() {
      this.deleteVisible = false
    },
    afterDeleteClose() {
      this.deleteLoading = false
    },
    toAddCourse() {
      this.addCourse = true
      this.$refs.newCourse.getCourseList()
    },
    classMoveItem(data) {
      this.moveVisible = true
      this.selectIds = [data.courseId]
    },
    moveClosed() {
      this.moveLoading = false
      this.postId2 = null
    },
    moveClose(done) {
      done()
    },
    moveSubmit() {
      if (this.postId2 === null) {
        this.$message.warning('请选择选择想要移动到的岗位！')
        return
      }
      this.moveLoading = true
      let payload = {
        postId: this.postId2,
        courseIds: this.selectIds
      }
      courseApi
        .movePostLesson(payload)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('课程移动成功')
            this.moveVisible = false
            this.fetchCourse(true)
          } else {
            this.moveLoading = false
          }
        })
        .catch(() => {
          this.moveLoading = false
        })
    },
    lessonMove(data) {
      // this.postId = data.id
      this.postId2 = data.id
    },
    addFinish() {
      this.addCourse = false
      this.fetchCourse(true)
    },
    addCancel() {
      this.addCourse = false
      this.itemPostId = 0
    },
    addCategory() {
      const addCategoryDTO = {
        name: this.additem,
        parentId: 0
      }
      courseApi.addCategory(addCategoryDTO).then(res => {})
    },
    getRooter() {
      courseApi.getRooter().then(res => {})
    },
    getAllPostById() {
      functionApi.getAllPostDataById(0).then(res => {
        this.dataId = res.res[0].id
        this.postId = res.res[0].id
        let arr = JSON.stringify(res.res)
        this.treeData = JSON.parse(arr.replace(/name/g, 'title'))
        this.fetchCourse()
      })
    },
    Show() {
      this.isShow = this.isShow !== true
    },
    choosePost(data) {
      this.showBackground = false
      if (data.length !== 0) {
        this.postId = data.id
        this.itemPostId = data.id
        this.fetchCourse(true)
      } else {
        this.postId = 0
        this.itemPostId = 0
      }
    },
    cancelAdd() {
      this.addCourse = false
    },
    handleChangeManager(id, status) {
      if (status === 'singleChangeManager') {
        this.courseManagerStatus = 'singleChangeManager'
        this.showManager = true
        this.courseId = [id]
        userApi.getManagerList().then(res => {
          this.managerList = res.res.map(item => {
            return item
          })
        })
      } else {
        if (this.selectIds.length === 0) {
          this.$message.error('您还未选择要更换管理员的课程')
        } else {
          this.showManager = true
          userApi.getManagerList().then(res => {
            this.managerList = res.res.map(item => {
              return item
            })
          })
        }
      }
    },
    chooseManager(id) {
      this.managerId = id
    },
    changeManager() {
      const payload = {
        courseIds: this.courseManagerStatus === 'singleChangeManager' ? this.courseId : this.selectIds,
        managerId: this.managerId
      }
      courseApi.changeManager(payload).then(res => {
        this.$message.success('更换成功')
        this.showManager = false
      })
    },
    advanceSearch() {
      const payload = {
        endTime: this.searchForm.endTime.valueOf(),
        keyword: this.searchForm.name,
        page: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        },
        startTime: this.searchForm.beginTime.valueOf(),
        status: this.searchForm.status
      }
      this.loading = true
      courseApi
        .advanceSearch(payload)
        .then(res => {
          this.courses.data = []
          this.courses.data = res.res.data
          this.courses.total = res.res.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleEdit(row) {
      this.editForm = row
      this.edit = true
    },
    getCourseList() {
      const payload = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }
      this.loading = true
      courseApi.getCourseList(payload).then(res => {
        this.courses.data = []
        this.courses.data = res.res.data
        this.courses.total = res.res.total
        this.courseCard.data = []
        this.courseCard.data = res.res.data
        this.courseCard.total = res.res.total
        this.loading = false
      })
    },
    //根据关键词搜索，非高级，包含表格、卡片格式
    searchByKeyword() {
      if (this.searchForm.name === '') {
        this.fetchCourse(true)
      } else {
        const payload = {
          postId: this.postId,
          keyword: this.searchForm.name,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        }
        this.loading = true
        courseApi.searchPostCourse(payload).then(res => {
          this.courses.data = []
          this.courseCard.data = res.res.data
          this.courses.total = res.res.total
          this.loading = false
        })
      }
    },
    selectCourses(ids) {
      this.itemData = ids
      this.selectIds = []
      for (let i = 0; i < ids.length; i++) {
        this.selectIds.push(ids[i].courseId)
      }
    },
    handleDelCourses() {
      if (this.selectIds.length === 0) {
        this.$message.error('您还未选择要删除的课程')
      } else {
        this.deleteTitle = '确定要删除这些课程？'
        this.$refs.deleteCourseDialog.open()
        this.delStatus = 'multi'
      }
    },
    handleDelCourse(id) {
      this.deleteTitle = '确定要删除此课程？'
      this.$refs.deleteCourseDialog.open()
      this.delCourseIds = [id]
      this.delStatus = 'single'
    },
    delCourse() {
      this.deleteLoading = true
      let payload = {
        courseIds: this.delStatus === 'single' ? this.delCourseIds : this.selectIds,
        postId: this.postId
      }
      courseApi
        .deletePostCourses(payload)
        .then(res => {
          if (res) {
            this.$message.success('删除成功')
            if (this.isShowCard === false) {
              this.searchType !== 'advanceSearch' ? this.searchByKeyword() : this.advanceSearch()
            } else {
              this.fetchCourse(true)
            }
            this.deleteVisible = false
          } else {
            this.$message.error('删除失败')
          }
        })
        .finally(() => {
          this.deleteLoading = false
        })
    },
    toNewCourse() {
      this.$router.push('/course/newPaper')
    },
    gotoCourseDetail(row) {
      this.$router.push({ name: 'courseDetail', params: { courseId: row.courseId } })
    },
    changePage(page) {
      this.pageNumber = page - 1
      this.handlePageChange()
    },
    changePageSize(size) {
      this.pageSize = size
      this.handlePageChange()
    },
    handlePageChange() {
      let form = this.searchForm
      if (form.name === '' && form.endTime === '' && form.beginTime === '' && form.status === '') {
        courseApi
          .getCourseListByPost({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            postId: this.postId
          })
          .then(res => {
            this.courseCard.data = res.res.data
          })
      } else {
        this.advanceSearch()
      }
    },
    handleClickOutside(event) {
      let containsTarget = this.$refs.hiddenDropdown.$parent.$el.contains(event.target)
      if (!containsTarget) {
        this.searchVisible = false
      }
    },
    handleReachBottom() {
      let endAnimation = () => {
        clearTimeout(this.loadingTimeout)
        this.loadingText = '已经到底了╮(￣▽￣)╭'
        this.loadingTimeout = setTimeout(() => (this.loadingText = '加载中'), 2000)
        return Promise.resolve()
      }
      if (this.courseCard.total === this.courseCard.data.length) {
        return endAnimation()
      } else {
        return this.fetchCourse()
      }
    },
    //卡片显示方式，获取课程
    fetchCourse(refresh = false) {
      this.loading = true
      if (refresh) {
        this.courseCard.page = 0
      }

      let promise =
        this.searchKeyword === ''
          ? courseApi.getCourseListByPost({
              pageNumber: this.courseCard.page,
              pageSize: this.pageSize,
              postId: this.postId
            })
          : courseApi.searchByKeyword({
              keyword: this.searchKeyword,
              page: {
                pageNumber: this.courseCard.page,
                pageSize: this.pageSize
              }
            })

      promise
        .then(res => {
          let data = res.res
          this.courseCard.total = data.total
          this.courseCard.data = refresh ? data.data : this.courseCard.data.concat(data.data)
          this.courseCard.page++
        })
        .finally(() => {
          this.loading = false
        })
    },
    back() {
      let scrollContainer = this.$refs.scroll.$el.childNodes[0]
      scrollContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    showCard() {
      this.isShowCard = !this.isShowCard
      if (this.isShowCard === true) {
        setTimeout(() => {
          let scrollContainer = this.$refs.scroll.$el.childNodes[0]
          scrollContainer.onscroll = () => {
            this.backTop = scrollContainer.scrollTop >= 100
          }
        }, 0)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../theme/variables';
@import '~@/theme/dragDiv';
.cation {
  color: #3a4e64ff;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: #6a8abeff;
  }
}
.yt-content {
  height: 100%;
  width: 100%;
  .func-bar {
    margin-bottom: 10px;
    width: 100%;
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 20px;
    height: 56px;

    .filter-btn {
      margin-right: 2%;
    }

    ::v-deep .search-container {
      display: flex;
      align-items: center;
      .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
      }
      .ivu-input:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }

      .advanced-search {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .func {
    margin-bottom: 10px;
    width: 100%;
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 20px;
    height: 56px;

    .filter-btn {
      margin-right: 20px;
    }

    ::v-deep .search-container {
      display: flex;
      align-items: center;
      .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
      }
      .ivu-input:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }

      .advanced-search {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .table-container {
    width: 100%;
    height: calc(100% - 66px);
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ::v-deep .flex-table {
      border-bottom: 1px solid #e8eaec;

      .ivu-table-row {
        cursor: pointer;
      }

      .status-container {
        display: flex;
        align-items: center;

        .status {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      .operation {
        cursor: pointer;
        color: @primary-color;
        transition: all @default-transition;
        &:hover {
          color: @primary-hover-color;
        }
      }

      .selection-column {
        width: 60px;
      }

      .name-column {
        width: 20%;
      }

      .description-column {
        flex: auto;
        width: 20%;
        overflow: hidden !important;
      }

      .type-column {
        width: 20%;
      }

      .status-column {
        width: 90px;
      }

      .action-column {
        width: 9%;
      }
    }

    .page {
      flex: 0 0 auto;
      padding: 10px;
      font-size: 14px;

      .page-options {
        float: left;
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

::v-deep .form {
  padding: 40px;
  text-align: center;

  .search-btn {
    width: 106px;
  }
}

.scroll-container {
  background-color: @layout-body-background;
  flex: 1 1 auto;
  overflow: auto;

  ::v-deep .ivu-scroll-wrapper {
    height: 100%;

    .ivu-scroll-container {
      height: 100% !important;
      overflow: scroll;

      .ivu-scroll-content {
        /*卡片阴影会被遮挡，故多 2px*/
        padding-left: 2px;
      }
    }
  }

  .course-list {
    display: flex;
    flex-flow: row wrap;
    margin-left: -20px;
    ::v-deep .course-card {
      margin: 0 0 20px 20px;
    }
  }
  .top {
    position: fixed;
    right: 20px;
    bottom: 100px;
    background: rgba(@primary-color, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color @default-transition;
    width: 40px;
    height: 40px;
    line-height: 40px;

    &:hover {
      background: rgba(@primary-color, 0.9);
    }

    svg {
      transform: rotate(180deg);
    }
  }
}

.manager ::v-deep .ivu-modal {
  text-align: center;
  .ivu-modal-body {
    margin-top: -30px;
  }
}
.managerList {
  height: 30px;
  border-radius: 4px;
  text-align: left;
  padding: 5px 0 0 10px;
  cursor: pointer;
  &:hover {
    background-color: #e4e9efff;
  }
  &:focus {
    background-color: #e4e9efff;
    outline: none;
    font-weight: bold;
  }
}
.left {
  .top {
    height: 56px;
    background: #e4e9ef;
    font-size: 16px;
    color: #3a4e64;
    font-weight: 400;
    padding: 16px 83px;
    display: flex;
    align-items: center;
  }
  .tree {
    padding: 15px 12px;
    text-align: left;
    height: calc(100% - 56px);
    overflow: auto;
  }
}
::v-deep .ivu-tree ul {
  font-size: 14px;
  color: #5e6470;
}
.show {
  cursor: pointer;
}
.addIt {
  cursor: pointer;
  font-size: 14px;
  color: #5e6470ff;
  &:hover {
    color: #6a8abeff;
  }
}
.showTable {
  margin-right: 20px;
  cursor: pointer;
}
/*.desc {*/
/*  width: 200px;*/
/*  text-overflow: ellipsis;*/
/*}*/
.lesson {
  margin-left: 8px;
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 24px;
  color: rgba(58, 78, 100, 1);
  opacity: 1;
}
.buttom {
  font-size: 14px;
  color: #bdc3cb;
  &:hover {
    color: #5e6470;
  }
}
.yt-dialog-large {
  ::v-deep .el-dialog {
    margin-bottom: 0;
  }
  ::v-deep .el-dialog__body {
    max-height: 85vh;
  }
}
</style>
